var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-top-dashboard',{attrs:{"neutral":""}}),_c('v-container',{staticClass:"pt-0 px-6 mt-n16 container-general",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-n3",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"mt-n3",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"card-shadow v-card-blue px-0"},[_c('v-card-title',{staticClass:"pt-0 card-title-bloque"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"4"}},[_c('p',{staticClass:"tit_card"},[_vm._v(" "+_vm._s(_vm.$t("Formularios"))+" ")])]),_c('v-col',{attrs:{"cols":"3","offset":"3"}},[_c('v-text-field',{staticClass:"\n                  input-style\n                  font-size-input\n                  text-light-input\n                  placeholder-light\n                  input-icon\n                ",attrs:{"hide-details":"","dense":"","flat":"","filled":"","solo":"","height":"43","placeholder":_vm.$t('Buscar')},model:{value:(_vm.searchTermHelper),callback:function ($$v) {_vm.searchTermHelper=$$v},expression:"searchTermHelper"}},[_c('template',{slot:"prepend-inner"},[_c('v-icon',{attrs:{"color":"#adb5bd","size":".875rem"}},[_vm._v(" fas fa-search ")])],1)],2)],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"float-right",attrs:{"elevation":"0","large":"","block":"","color":"light-green darken-2  white--text"},on:{"click":function($event){return _vm.goToEditor()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" fas fa-plus ")]),_vm._v(" "+_vm._s(_vm.$t("Nuevo formulario"))+" ")],1)],1)],1),_c('v-row',{}),_c('v-card-text',{staticClass:"nocard"},[_c('v-data-table',{staticClass:"table thead-light",attrs:{"footer-props":{
                    'items-per-page-options': [5, 10, 15, 25, 50, 100],
                    'disable-pagination': true,
                    'items-per-page-text': _vm.$t('Items por página'),
                  },"disable-pagination":"","mobile-breakpoint":"0","headers":_vm.headers,"items":_vm.content,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.total,"options":_vm.options,"dense":"","loading":_vm.loading,"loading-text":_vm.$t('Cargando datos... Espere por favor.'),"no-data-text":_vm.$t('No hay resultados.')},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-row',{staticClass:"pa-5",attrs:{"align":"center","justify":"center"}},[_c('v-btn-toggle',{attrs:{"rounded":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btns_table",attrs:{"color":"white","elevation":"0"},on:{"click":function($event){return _vm.goToEdit(item.id)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-edit"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Editar")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btns_table",attrs:{"color":"white","elevation":"0"},on:{"click":function($event){return _vm.preview(item)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-eye"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Previsualizar")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btns_table btns_table_eliminar",attrs:{"color":"white","elevation":"0"},on:{"click":function($event){return _vm.remove(item.id)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-trash"})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Eliminar")))])])],1)],1)]}}])})],1),_c('v-card-actions',{staticClass:"py-0 my-0 mt-lg-n13"},[_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#0094de","length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('overlay-manager',{on:{"overlayClosed":_vm.overlayClosed}},[_c('preview-form',{attrs:{"form-data-table":_vm.formDataTable}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }